import dayjs from 'dayjs';
import numeral from 'numeral';

import store from '@/store/index';

const PTTColor = [
  '#F27649',
  '#844D9E',
  '#F29D35',
  '#FA339A',
  '#6F984C',
  '#F25252',
  '#F2CC66',
  '#14CB92',
  '#BD7A45',
  '#14A697',
  '#c53440',
  '#14ABD2',
  '#F28682',
  '#1A6DB4',
  '#625B6F',
];

const dateFormat = {
  hourly: 'DD MMM YYYY: HH:mm',
  daily: 'DD MMM YYYY',
  monthly: 'DD MMM YYYY',
};

const globalOptions = {
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  noData: 'No data to display',
  plotOptions: {
    series: {
      showInLegend: true,
      states: {
        hover: {
          enabled: true,
        },
      },
    },
    bar: {
      states: {
        hover: {
          enabled: true,
        },
      },
    },
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.point._dataLabel;
        },
        style: {
          color: '#000',
        },
        useHTML: false,
      },
      showInLegend: false,
      startAngle: 45,
    },
  },
  chart: {
    spacing: [60, 10, 15, 10],
  },
  series: [],
};

const getColor = (val, colorIndex) => {
  const colorId = val.toLowerCase();
  const colorList = store.state.account.colors;
  let colorResult = '#333';
  if (colorList[colorId]) {
    colorResult = colorList[colorId];
  } else if (typeof colorIndex === 'number') {
    const targetIndex = colorIndex % PTTColor.length;
    colorResult = PTTColor[targetIndex];
  }
  return colorResult;
};

const getChartGranularity = (sinceDate, untilDate) => {
  const since = dayjs(sinceDate);
  const until = dayjs(untilDate);
  const diff = until.diff(since, 'days');
  if (diff <= 1) return 'hourly';
  else if (diff <= 31) return 'daily';
  else if (diff <= 1000) return 'monthly';
  else return 'yearly';
};

export default {
  getPieChartOption() {
    return {
      ...globalOptions,
      chart: {
        type: 'pie',
        className: 'levelPieChart',
        animation: true,
        height: 450,
        margin: [20, 0, 20, 0],
        events: {
          render: null,
        },
      },
      series: [],
      drilldown: {
        series: [],
      },
      legend: {
        itemStyle: {
          color: '#333333',
          cursor: 'pointer',
          fontSize: '18px',
          fontWeight: 'bold',
          textOverflow: 'ellipsis',
        },
      },
      tooltip: {
        formatter: function () {
          return this.point._tooltip;
        },
        useHTML: true,
        className: 'pie-tooltip',
      },
    };
  },
  getDefaultChartOption() {
    return {
      ...globalOptions,
      navigation: {
        buttonOptions: {
          verticalAlign: 'top',
          y: -51,
        },
      },
      xAxis: {
        type: 'datetime',
        // labels: {
        //   formatter: function () {
        //     let granularity = this.chart.tooltip.options.granularity;
        //     let time;
        //     if (granularity === 'hourly') {
        //       time = dayjs(this.value, 'x').format('MMM D, h:mm a');
        //     } else if (granularity === 'daily') {
        //       time = dayjs(this.value, 'x').format('MMM D');
        //     } else if (granularity === 'monthly') {
        //       time = dayjs(this.value, 'x').format('MMM YYYY');
        //     } else if (granularity === 'yearly') {
        //       time = dayjs(this.value, 'x').format('YYYY');
        //     }
        //     return time;
        //   },
        // },
        crosshair: {
          enabled: true,
        },
      },
      yAxis: {
        title: {
          text: 'Total',
        },
        type: 'linear',
      },
      tooltip: {
        formatter: function () {
          console.log(this.points[0].series.userOptions.custom);

          let { granularity } = this.points[0].series.userOptions.custom;
          let time;
          if (granularity === 'hourly') {
            time = dayjs(this.x).format('dddd, MMM D YYYY, h:mm a');
          } else if (granularity === 'daily') {
            time = dayjs(this.x).format('dddd, MMM D YYYY');
          } else if (granularity === 'monthly') {
            time = dayjs(this.x).format('MMM YYYY');
          } else if (granularity === 'yearly') {
            time = dayjs(this.x).format('YYYY');
          }
          let str = '<span>' + time + '</span><br/>';
          for (var i in this.points) {
            str =
              str +
              '<span style="color:' +
              this.points[i].color +
              '">\u25CF</span> ' +
              this.points[i].series.name +
              ' : <b>' +
              numeral(this.points[i].y).format('0,0') +
              '</b><br/>';
          }
          return str;
        },
        useHTML: true,
        shared: true,
      },
      size: {},
      time: {
        useUTC: true,
        timezoneOffset: -420,
      },
    };
  },
  getTimeOpt(inputTime, graphTime) {
    const result = {
      time: {
        sinceDate: null,
        untilDate: null,
      },
      title: '',
    };
    const selectedTime = dayjs(inputTime);
    let since, until;
    if (graphTime && graphTime.timeRange) {
      const fmt = dateFormat[graphTime.timeRange];
      if (graphTime.timeRange === 'hourly') {
        since = selectedTime;
        until = selectedTime.add(1, 'hours');
        result.title = `between ${since.format(fmt)} to ${until.format(fmt)}`;
      } else if (graphTime.timeRange === 'daily') {
        since = selectedTime.startOf('day');
        until = selectedTime.endOf('day');
        result.title = `on ${selectedTime.format(fmt)}`;
      } else if (graphTime.timeRange === 'monthly') {
        since = selectedTime.startOf('month');
        until = selectedTime.endOf('month');
        result.title = `between ${since.format(fmt)} to ${until.format(fmt)}`;
      }
    } else {
      since = selectedTime.startOf('day');
      until = selectedTime.endOf('day');
      result.title = `on ${since.format(dateFormat.daily)}`;
    }
    result.time.sinceDate = since.valueOf();
    result.time.untilDate = until.valueOf();
    return result;
  },
  async prepareSeriesData({
    graphData,
    modifyValueFn,
    drilldownFn,
    seriesType,
    granularity,
  }) {
    // TODO add granularity - custom
    const dataKeys = Object.keys(graphData);
    const series = [];
    for (let dataKey of dataKeys) {
      const dataList = [];
      const categoryData = graphData[dataKey];
      for (let eachDot of categoryData.data) {
        let yValue = eachDot.y;
        if (modifyValueFn) {
          yValue = modifyValueFn(eachDot.y);
        }
        dataList.push({
          x: eachDot.x,
          y: yValue,
          name: dayjs(eachDot.x).format('dddd, MMM D, YYYY'),
          category: dataKey,
        });
      }
      const color = getColor(dataKey, series.length);
      series.push({
        id: dataKey,
        type: seriesType,
        name: dataKey,
        data: dataList,
        point: {
          events: {
            click: (e) => drilldownFn(e.point.options),
          },
        },
        marker: {
          enabled: false,
        },
        color,
        visible: true,
        showInNavigator: true,
        custom: {
          granularity,
        },
      });
    }
    return series;
  },
  getChartGranularity,
  modifyGranularity(opt, args) {
    const chartOptions = JSON.parse(JSON.stringify(opt));
    let granularity = 'daily';
    if (args && args.time) {
      if (!args.graphTime) args.graphTime = {};
      args.graphTime.sinceDate = args.time.sinceDate;
      args.graphTime.untilDate = args.time.untilDate;
      granularity = getChartGranularity(
        args.time.sinceDate,
        args.time.untilDate
      );
      args.graphTime.timeRange = granularity;
      args.time = undefined;
    } else if (args && args.graphTime.timeRange) {
      granularity = args.graphTime.timeRange;
      // args.granularity = args.graphTime.timeRange;
      // TODO check if needed for tickInterval or pointRange
    } else {
      try {
        chartOptions.xAxis.tickInterval = null;
        chartOptions.plotOptions.series.pointRange = null;
      } catch (e) {
        // console.log('Chart error', e);
      }
    }
    return {
      chartOptions,
      granularity,
    };
  },
  getColor,
};
