<template>
  <div class="sentiment-page">
    <template v-if="loading">...</template>
    <template v-else>
      <v-tabs v-model="tab" centered>
        <v-tab>Overview</v-tab>
        <v-tab>Comparison</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <LevelPieChart type="overview" />
          <CategoryBarChart />
          <SentimentBarChart />
        </v-tab-item>
        <v-tab-item>
          <LevelPieChart type="comparison" />
          <DailySentimentComparison />
          <DailyVolumeComparison />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import LevelPieChart from '@/components/Graph/LevelPieChart.vue';
import CategoryBarChart from '@/components/Graph/CategoryBarChart.vue';
import SentimentBarChart from '@/components/Graph/SentimentBarChart.vue';
import DailySentimentComparison from '@/components/Graph/DailySentimentComparison.vue';
import DailyVolumeComparison from '@/components/Graph/DailyVolumeComparison.vue';

export default {
  name: 'Sentiment',
  components: {
    LevelPieChart,
    CategoryBarChart,
    SentimentBarChart,
    DailySentimentComparison,
    DailyVolumeComparison,
  },
  data() {
    return {
      loading: true,
      tab: null,
    };
  },
  async created() {
    await this.$store.dispatch('filter/initFilterCriteria').catch(() => {});
    this.loading = false;
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
    }),
  },
};
</script>
