<template>
  <v-card class="graph-base">
    <v-card-title class="pb-0">
      <v-layout class="graph-title" align-center>
        <v-icon class="title-icon mr-2" small>{{ icon }}</v-icon>
        <span>{{ title }}</span>
      </v-layout>
      <v-spacer></v-spacer>
      <v-layout align-center class="level-select" v-if="hasLevelSelect">
        <v-select
          class="smaller-select"
          v-model="selectedLevel"
          :items="categoryData"
          dense
          small
          hide-details
          height="22"
          item-text="name"
          item-value="level"
          @change="$emit('level-change', selectedLevel)"
        ></v-select>
      </v-layout>
    </v-card-title>
    <v-card-text class="card-content">
      <div class="log-toggle" v-if="hasLogScale">
        <v-switch
          v-model="isLogScale"
          inset
          hide-details
          dense
          class="small-switch"
          @change="$emit('scale-change', isLogScale)"
        >
          <template v-slot:label>
            <span class="label"> Log Scale </span>
          </template>
        </v-switch>
      </div>
      <highcharts :ref="chartRef" :options="options"></highcharts>
    </v-card-text>
  </v-card>
</template>

<script>
import highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
exporting(highcharts);
offlineExporting(highcharts);

import { Chart } from 'highcharts-vue';
import { mapState } from 'vuex';

export default {
  name: 'GraphBase',
  props: {
    hasLogScale: Boolean,
    hasLevelSelect: Boolean,
    options: Object,
    title: String,
    icon: String,
    chartRef: String,
  },
  data() {
    return {
      isLogScale: false,
      selectedLevel: 0,
    };
  },
  components: {
    highcharts: Chart,
  },
  computed: {
    ...mapState({
      categoryData: (state) => state.config.categoryData,
    }),
  },
};
</script>

<style lang="scss" scoped>
.graph-base {
  margin: 16px;
  .graph-title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    .title-icon {
      color: #333 !important;
    }
  }
  .level-select {
    flex: 0 0 120px;
    font-size: 12px;
    margin-top: -8px;
  }
  .card-content {
    position: relative;
    .log-toggle {
      position: absolute;
      top: -10px;
      left: 16px;
      z-index: 1;
      .small-switch {
        height: 18px;
        .label {
          font-size: 12px !important;
        }
      }
    }
  }
}
</style>
