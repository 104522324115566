<template>
  <GraphBase
    ref="pie"
    :chartRef="'innerPie'"
    :title="title"
    icon="fa-pie-chart"
    :options="options"
    :hasLevelSelect="true"
    @level-change="onCategoryLevelChange"
  >
  </GraphBase>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import numeral from 'numeral';

import graphHelper from './graph.js';
import api from '@/services/api.js';
import GraphBase from './GraphBase.vue';

const ButtonStyle = { stroke: 'black', 'stroke-width': 1, fill: 'white' };
const HoverStyle = { stroke: 'black', 'stroke-width': 1, fill: '#ddd' };

export default {
  name: 'LevelPieChart',
  components: {
    GraphBase,
  },
  props: {
    type: String,
  },
  watch: {
    isEngagementMode: 'init',
    filterCriteria: {
      handler: 'init',
      immediate: true,
    },
  },
  data() {
    return {
      baseCategoryLevel: 0,
      categoryLevel: 0,
      pieLevel: 0,
      options: {},
      cachedSeries: [],
      pieLevelCriteria: [],
      // chart btn ...
      backButton: null,
      optionButton: null,
      optionButton2: null,
      optionCloseButton: null,
    };
  },
  created() {
    this.options = graphHelper.getPieChartOption();
    // this.init(true);
  },
  methods: {
    ...mapActions({
      showDrilldown: 'drilldown/showDrilldown',
    }),
    async init(clearCache, levelChange) {
      console.log('PIE INIT', this.type, clearCache, levelChange);

      this.destroyBackButton();
      // In case of change level - clear old cache
      if (clearCache) {
        this.pieLevel = 0;
        this.cachedSeries = [];
        this.pieLevelCriteria = [];
        this.destroyOptionButton();
      }
      // If lvl change
      let hasBackBtn = false;
      let prevPie = null;
      if (typeof levelChange === 'number') {
        prevPie = this.pieOrder[this.pieLevel];
        this.pieLevel += levelChange;
        if (this.pieLevel > 0) {
          hasBackBtn = true;
        }
      }
      // Do it.
      const criteria = this.getCriteria();
      const qs = {
        'chart-type': 'pie',
        level: this.baseCategoryLevel + this.categoryLevel,
      };
      // Base on current pie level
      const currentPie = this.pieOrder[this.pieLevel];
      const graphName = currentPie.name;
      let result;
      if (currentPie.type === 'sentiment') {
        result = await api.getSentimentPie(criteria, qs).catch(() => {});
      } else if (currentPie.type === 'category') {
        // If target is category check for change categoryLevel
        if (prevPie && prevPie.type === 'category') {
          // console.log('PLUSBF', this.categoryLevel, levelChange);
          // console.log('PLUSIt', prevPie, currentPie);
          this.categoryLevel += levelChange;
          qs.level = this.baseCategoryLevel + this.categoryLevel;
        }
        result = await api.getCategoryPie(criteria, qs).catch(() => {});
      } else if (currentPie.type === 'source') {
        result = await api.getSourcePie(criteria, qs).catch(() => {});
      }
      if (result && result.message && result.message.graphData) {
        if (hasBackBtn) {
          let prevTarget = 'N/A';
          if (prevPie) {
            prevTarget = prevPie.name;
          }
          const t = `< Back to ${prevTarget}`;
          this.backButton = this.renderButton(t, 10, 10, () => {
            this.backPieLevel();
          });
        }
        // Do extract data
        const { graphData } = result.message;
        let dataObj;
        if (this.isEngagementMode) {
          dataObj = graphData.engagement;
        } else {
          dataObj = graphData.count;
        }
        // Each key as name
        let isAllZero = true;
        const preSeries = [];
        const colorList = [];
        const dataKeys = Object.keys(dataObj);
        let total = 0;
        for (let k of dataKeys) {
          total += dataObj[k];
        }
        for (let k of dataKeys) {
          if (dataObj[k] > 0) {
            isAllZero = false;
          }
          const pointName = k.charAt(0).toUpperCase() + k.slice(1);
          const color = graphHelper.getColor(k, preSeries.length);
          const percent = numeral((dataObj[k] / total) * 100).format('0.00');
          const dataLabel = `<span class="pie-label">${pointName} <br> ${percent}% </span>`;
          const tooltip = `
            <span style="color:${color}">\u25CF </span>
            <span>${pointName} : </span><b>${percent}%</b><br>
            <span>Total : <b>${numeral(dataObj[k]).format('0,0')}<b></span>
          `;
          preSeries.push({
            name: k,
            y: dataObj[k],
            drilldown: true,
            color,
            dataLabels: {
              className: 'pie-label',
            },
            _dataLabel: dataLabel,
            _tooltip: tooltip,
          });
          colorList.push(color);
        }
        if (isAllZero) {
          this.options.series = [];
          this.loading = false;
        } else {
          // Calculate pie percent
          const finalData = preSeries.sort((a, b) => {
            const ay = a.y || 0;
            const by = b.y || 0;
            return ay - by;
          });
          this.options.series = [
            {
              name: graphName,
              point: {
                events: {
                  click: (e) => this.onChartClick(e),
                },
              },
              data: finalData,
              label: {
                minFontSize: 14,
                maxFontSize: 16,
              },
            },
          ];
        }

        // DEBUG
        // console.log('OVRRIDE', this.pieLevelCriteria);
        // console.log('PIELVL', this.pieLevel);
        // console.log('CATLVL', this.categoryLevel);
        // console.log('CACHE', this.cachedSeries);
      }
    },
    getCriteria() {
      // Modify criteria - consider create new object
      const criteria = JSON.parse(JSON.stringify(this.filterCriteria));
      // If has Override Criteria ?
      if (this.pieLevelCriteria && this.pieLevelCriteria.length > 0) {
        for (let c of this.pieLevelCriteria) {
          const { type, level, value } = c;
          if (type === 'category' && level !== 0) {
            const subCatObj = {
              level,
              category: [value],
            };
            if (criteria.subCategory) {
              // Already had subCat in criteria - add it
              criteria.subCategory.push(subCatObj);
            } else {
              criteria.subCategory = [subCatObj];
            }
          } else {
            // source / sentiment / category lvl0 format
            criteria[type] = [value];
            // If category level - Add all sub category for sure
            // TODO add subcat
            if (type === 'category') {
              // If
            }
          }
        }
      }
      // console.log('GET CRt', criteria);
      return criteria;
    },
    onChartClick(e) {
      const nextPie = this.pieOrder[this.pieLevel + 1];
      const clicked = e.point.name;
      // console.log('UMM', e, nextPie);
      const currentPie = this.pieOrder[this.pieLevel];
      if (nextPie) {
        const backupSeriesData = JSON.parse(
          JSON.stringify(this.options.series[0].data)
        );
        const backup = {
          name: currentPie.name,
          data: backupSeriesData,
        };
        const opt = {
          name: currentPie.name,
          type: currentPie.type,
          level: currentPie.level,
          value: clicked,
        };
        // If current level is category and has next is category
        if (currentPie.type === 'category' && nextPie.type === 'category') {
          // console.log('CP', currentPie, this.pieLevel);
          // show option for skip to source OR go to next level
          // TODO pls REFACTOR
          this.destroyOptionButton();
          const t = `> Get ${clicked} category`;
          const x = e.chartX;
          const y1 = e.chartY;
          this.optionButton = this.renderButton(t, x, y1, () =>
            this.nextLevel(opt, backup)
          );
          const y2 = e.chartY + 30;
          this.optionButton2 = this.renderButton('>> To source', x, y2, () =>
            this.skipLevel(opt, backup)
          );
          const y3 = e.chartY + 60;
          this.closeButton = this.renderButton('X Close menu', x, y3, () => {
            this.destroyOptionButton();
          });
        } else {
          // - Else if has next level to go then just go
          this.nextLevel(opt, backup);
        }
      } else {
        const { type } = currentPie;
        const criteria = this.getCriteria();
        criteria[type] = [clicked];
        // console.log('SHOW Drill', clicked, criteria);
        this.showDrilldown({
          payload: {
            title: 'Detail on pie chart',
            drilldownType: 'engage',
          },
          original: criteria,
        });
      }
    },
    renderButton(title, x, y, cb) {
      const { chart } = this.$refs.pie.$refs.innerPie;
      return chart.renderer
        .button(title, x, y, cb, ButtonStyle, HoverStyle)
        .add()
        .toFront();
    },
    nextLevel(overrideCriteria, backup, plusCount = 1) {
      // Backup current pie state
      this.cachedSeries.push(backup);
      // console.log('ONNEXT', overrideCriteria);
      this.destroyOptionButton();
      // - Add override criteria
      this.pieLevelCriteria.push({
        ...overrideCriteria,
      });
      this.init(false, plusCount);
    },
    skipLevel(overrideCriteria, backup) {
      let plusCount = 1;
      // Find how many level diff from this to source
      for (let i = this.pieLevel; i < this.pieOrder.length; i += 1) {
        if (this.pieOrder[i].type !== 'category') {
          plusCount = i - this.pieLevel;
          break;
        }
      }
      this.nextLevel(overrideCriteria, backup, plusCount);
    },
    backPieLevel() {
      // console.log('BF BACK', this.cachedSeries, this.pieLevel);
      this.destroyOptionButton();
      this.destroyBackButton();
      // If has cache - pop cache and use it.
      if (this.cachedSeries && this.cachedSeries.length > 0) {
        this.pieLevelCriteria.pop();
        const { data, name } = this.cachedSeries.pop();
        // Hydrate binding to click event
        this.options.series = [
          {
            name,
            point: {
              events: {
                click: (e) => this.onChartClick(e),
              },
            },
            data,
            label: {
              minFontSize: 14,
              maxFontSize: 16,
            },
          },
        ];
        if (this.categoryLevel > 0) this.categoryLevel -= 1;
        if (this.pieLevel > 0) this.pieLevel -= 1;
      } else {
        console.error('This is not cool, man.');
      }
      let cacheLength = this.cachedSeries.length;
      if (cacheLength > 0) {
        const latestSeries = this.cachedSeries[cacheLength - 1];
        const t = `< Back to ${latestSeries.name}`;
        this.backButton = this.renderButton(t, 10, 10, () => {
          this.backPieLevel();
        });
      }
      // console.log('BACKED', this.pieLevel, this.cachedSeries);
    },
    destroyOptionButton() {
      try {
        if (this.optionButton) {
          this.optionButton.destroy();
          this.optionButton = null;
        }
        if (this.optionButton2) {
          this.optionButton2.destroy();
          this.optionButton2 = null;
        }
        if (this.closeButton) {
          this.closeButton.destroy();
          this.closeButton = null;
        }
      } catch (e) {
        console.error(e);
      }
    },
    destroyBackButton() {
      if (this.backButton) {
        try {
          this.backButton.destroy();
          this.backButton = null;
        } catch (e) {
          console.error(e);
        }
      }
    },
    onCategoryLevelChange(level) {
      this.baseCategoryLevel = level;
      this.init(true);
    },
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
      filterCriteria: (state) => state.filter.filterCriteria,
    }),
    ...mapGetters({
      categoryData: 'config/categoryData',
    }),
    title() {
      if (this.type === 'overview') {
        return 'Segment Overview';
      } else if (this.type === 'comparison') {
        return 'Volume Comparison';
      }
      return '';
    },
    pieOrder() {
      let categoryObjList = [
        {
          type: 'category',
          name: 'categories',
          level: 0,
          categories: [],
        },
      ];
      if (this.categoryData && this.categoryData.length > 0) {
        categoryObjList = this.categoryData
          .filter((c) => {
            return c.level >= this.baseCategoryLevel;
          })
          .map((o) => {
            return { ...o, type: 'category' };
          });
        // console.log(categoryObjList);
      }
      if (this.type === 'overview') {
        return [
          {
            type: 'sentiment',
            name: 'Sentiment',
          },
          ...categoryObjList,
          {
            type: 'source',
            name: 'Source',
          },
        ];
      } else if (this.type === 'comparison') {
        return [
          ...categoryObjList,
          {
            type: 'source',
            name: 'Source',
          },
          {
            type: 'sentiment',
            name: 'Sentiment',
          },
        ];
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.level-pie-chart {
  margin: 16px;
  .graph-title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    .title-icon {
      color: #333 !important;
    }
  }
}
</style>
