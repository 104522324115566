<template>
  <GraphBase
    title="Daily Sentiment"
    icon="fa-line-chart"
    :options="options"
    @level-change="onCategoryLevelChange"
  ></GraphBase>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import graphHelper from './graph.js';
import api from '@/services/api.js';
import GraphBase from './GraphBase.vue';
export default {
  name: 'SentimentBarChart',
  components: {
    GraphBase,
  },
  data() {
    return {
      categoryLevel: 0,
      options: {},
    };
  },
  mounted() {
    const opt = graphHelper.getDefaultChartOption();
    // Override opt
    if (this.isEngagementMode) {
      opt.yAxis.title.text = 'Engagements';
    } else {
      opt.yAxis.title.text = 'Messages';
    }
    opt.plotOptions.series.stacking = 'normal';
    this.options = opt;
    // this.init();
  },
  methods: {
    ...mapActions({
      showDrilldown: 'drilldown/showDrilldown',
    }),
    async init() {
      // If modify criteria - consider create new object
      const criteria = this.filterCriteria;
      const qs = {
        'chart-type': 'line',
        level: this.categoryLevel,
      };
      // Set chart granularity
      const { chartOptions, granularity } = graphHelper.modifyGranularity(
        this.options,
        criteria
      );
      this.options = chartOptions;
      const result = await api
        .getSentimentHistoryGraph(criteria, qs)
        .catch(() => {});
      if (result && result.message && result.message.graphData) {
        const { graphData } = result.message;
        const series = await graphHelper.prepareSeriesData({
          graphData,
          seriesType: 'column',
          modifyValueFn: (yInput) => {
            let yValue;
            if (this.isEngagementMode) {
              yValue = yInput.engagement;
            } else {
              yValue = yInput.count;
            }
            return yValue;
          },
          drilldownFn: this.drillDownByPoint,
          granularity,
        });
        this.options.series = series;
      }
    },
    drillDownByPoint(pointOption) {
      const { category, x } = pointOption;
      const { time, title } = graphHelper.getTimeOpt(x);
      const criteria = {
        payload: {
          title: `${category} sentiment ${title}`,
          drilldownType: 'engage',
        },
        original: {
          ...this.filterCriteria,
          sentiment: [category],
          paging: {
            page: 1,
            recordPerPage: 25,
          },
          time,
        },
      };
      this.showDrilldown(criteria);
    },
    onCategoryLevelChange(level) {
      this.categoryLevel = level;
      this.init();
    },
  },
  watch: {
    isEngagementMode: 'init',
    filterCriteria: {
      handler: 'init',
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      isEngagementMode: (state) => state.filter.isEngagementMode,
      filterCriteria: (state) => state.filter.filterCriteria,
    }),
  },
};
</script>
